import React from "react";
import PropTypes from "prop-types";
import { getFluidGatsbyImage } from "gatsby-source-sanity";
import { projectId, dataset } from "../../../utils/sanity";

import Heading from "../../heading";
import Image from "./image";

const sanityConfig = { projectId, dataset };

function HeroGeneral({ title, body, image }) {
  const imageAssetId = image?.asset.id;
  const fluidProps = getFluidGatsbyImage(imageAssetId, { maxWidth: 1024 }, sanityConfig);

  return (
    <div className="bg-black mb-8 md:mb-16 relative hero-generic py-0 md:py-16 flex">
      <div className="max-w-screen-desktop mx-auto z-10 px-6 relative flex-1 flex flex-col justify-center">
        <div className="md:w-1/2 z-10 text-white">
          <Heading className="leading-snug uppercase text-5xl md:text-8xl">
            {title}
          </Heading>
          {body && <p className="font-standard leading-relaxed text-2xl">{body}</p>}
        </div>
      </div>

      {image ? (
        <>
          <Image fluid={fluidProps} />{" "}
          <div
            style={{
              background: "rgba(0, 0, 0, .5)",
              position: "absolute",
              height: "100%",
              width: "100%",
              zIndex: 0,
              top: 0,
            }}
          />
        </>
      ) : (
        <Image />
      )}
    </div>
  );
}

HeroGeneral.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  image: PropTypes.shape({}),
};

HeroGeneral.defaultProps = {
  body: null,
  image: null,
};

export default HeroGeneral;

import React, { useState, useEffect } from "react";
import { parse } from "query-string";

import Layout from "../layouts";
import Button from "../components/button";
import HeroGeneral from "../components/heroes/general";
import Heading from "../components/heading";
import TextInput from "../components/text-input";
import TextareaInput from "../components/textarea-input";

const ContactUsPage = () => {
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (window && window.location) {
      const result = parse(window.location.search);
      setSuccess(result.success);
    }
  }, []);

  return (
    <Layout title="Contact Us">
      <HeroGeneral
        title="Contact Us"
        body={
          success
            ? "Thank you. We have received your enquiry and will be in touch shortly."
            : null
        }
      />

      <div className="flex flex-col md:flex-row max-w-screen-desktop mx-auto px-6">
        <div className="order-2 md:order-1 md:w-3/4 md:mr-20">
          <Heading variant="h3" className="text-4-1/2xl leading-none mb-8 md:mb-12">
            General Enquiries
          </Heading>

          <form
            name="Enquiry Form"
            method="POST"
            netlify-honeypot="bot-field"
            data-netlify="true"
            action="/contact-us?success=true"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="Enquiry Form" />

            <div className="md:flex mb-8 md:mb-12">
              <TextInput
                className="mb-8 md:mb-0 md:mr-20"
                isRequired
                label="First Name"
                name="first-name"
                placeholder="First Name"
                type="text"
              />

              <TextInput
                label="Last Name"
                name="last-name"
                placeholder="Last Name"
                type="text"
                isRequired
              />
            </div>

            <div className="md:flex mb-8 md:mb-12">
              <TextInput
                className="mb-8 md:mb-0 md:mr-20"
                isRequired
                label="Email Address"
                name="email-address"
                placeholder="Email Address"
                type="email"
              />

              <TextInput
                label="Contact Number"
                name="telephone-number"
                placeholder="Contact Number"
                type="tel"
              />
            </div>

            <div className="mb-8 md:mb-6">
              <TextareaInput
                placeholder="Enter your message..."
                label="Your Message"
                name="message"
                isRequired
              />
            </div>
            <div className="mb-8">
              <p className="font-standard">
                We are committed to protecting your privacy. We will only use the
                information that we collect about you lawfully (in accordance with the
                Data Protection Act 1998 and GDPR). We will only collect the information
                you provide in this form to respond to any queries which you may have. We
                will not e-mail you in the future unless you have given us your consent.
                By submitting this form you agree to these terms.
              </p>
            </div>

            <div className="mb-10 md:mb-20">
              <Button variant="secondary">Submit</Button>
            </div>
          </form>
        </div>

        <div className="order-1 md:order-2 font-standard md:w-1/4 mb-10 md:mb-0">
          <Heading
            variant="h3"
            className="text-3xl md:text-4-1/2xl leading-none mb-4 md:mb-10"
          >
            Address
          </Heading>
          <ul className="leading-relaxed text-lg">
            <li>6B Guiseley Way</li>
            <li>Durham Lane Industrial Estate</li>
            <li>Egglescliffe</li>
            <li>TS16 0RF</li>
            <br />
            <li>
              <a
                target="_blank"
                rel="noopener noreferrer"
                className="underline"
                href="https://www.google.com/maps/place/Distinct+Performance/@54.5299964,-1.360682,17z/data=!3m1!4b1!4m5!3m4!1s0x487e9330e682c171:0x5e90379a4ef34859!8m2!3d54.5299933!4d-1.3584933"
              >
                Find us on Google Maps
              </a>
            </li>
            <br />
            <li>
              T: <a href="tel:07800852347">07800 852347</a>
            </li>
            <li>
              E: <a href="mailto:distinctperform@gmail.com">distinctperform@gmail.com</a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default ContactUsPage;

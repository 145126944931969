import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const TextareaInput = ({ isRequired, label, name, placeholder, rows, isValid }) => {
  const [textareaValue, setTextareaValue] = useState("");
  return (
    <>
      <label
        className={classNames("block mb-4 pl-4 text-sm tracking-wider uppercase", {
          "text-red-500": !isValid,
        })}
        htmlFor={name}
      >
        {label} {isRequired && <span>&#42;</span>}
      </label>
      <textarea
        className={classNames(
          "block bg-distinct-grey-concrete font-mono leading-loose pl-4 placeholder-distinct-grey-dove py-2 tracking-wide w-full border-2",
          {
            "border-red-500": !isValid,
          },
        )}
        id={name}
        name={name}
        placeholder={placeholder}
        required={isRequired}
        rows={rows}
        value={textareaValue}
        onChange={e => setTextareaValue(e.target.value)}
      />
    </>
  );
};

TextareaInput.propTypes = {
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  rows: PropTypes.number,
  isValid: PropTypes.bool,
};

TextareaInput.defaultProps = {
  isRequired: false,
  rows: 4,
  isValid: true,
};

export default TextareaInput;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const TextInput = ({
  className,
  isRequired,
  label,
  name,
  placeholder,
  type,
  isValid,
}) => (
  <div className={classNames("w-full", className)}>
    <label
      className={classNames("block mb-4 pl-4 text-sm tracking-wider uppercase", {
        "text-red-500": !isValid,
      })}
      htmlFor={name}
    >
      {label} {isRequired && <span>&#42;</span>}
    </label>
    <input
      className={classNames(
        "block bg-distinct-grey-concrete font-mono leading-loose pl-4 placeholder-distinct-grey-dove py-2 tracking-wide w-full border-2",
        {
          "border-red-500": !isValid,
        },
      )}
      id={name}
      name={name}
      type={type}
      placeholder={placeholder}
      required={isRequired}
    />
  </div>
);

TextInput.propTypes = {
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
};

TextInput.defaultProps = {
  className: null,
  isRequired: false,
  isValid: true,
};

export default TextInput;

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const GenericImage = ({ fluid }) => {
  const data = useStaticQuery(graphql`
    query {
      heroImage: file(relativePath: { eq: "generic-hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2800, traceSVG: { background: "#fff", color: "#000" }) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={fluid || data.heroImage.childImageSharp.fluid}
      className="hidden md:block w-full h-full top-0 left-0 object-cover"
      style={{
        position: "absolute",
      }}
    />
  );
};

export default GenericImage;
